import React from "react"
import { graphql, PageProps } from "gatsby"
import GSAP from "gsap"

import * as scss from "./index.module.scss"
import { SEO } from 'components/seo/Seo'
import { IHomePageData } from "data/dataStruct"
import Scroll from 'lib/scroll'

const componentName = 'Projects Page'
const Index: React.FC<PageProps<IHomePageData>> = ({ data }) => {
  const { page } = data

  React.useEffect(() => {
    if (Scroll.isSet) {
      const timeLine = GSAP.timeline({})

      const test = new Promise((resolve, reject) => {
        Scroll.disable()
        setTimeout(() => {
          resolve(true);
        }, 100);
      })

      test.then(() => {
        Scroll.reset()
      })
    }
  }, [])

  return (
    <div className={scss.root}>
      <SEO title={page.data.meta_title} description={page.data.meta_description} />
    </div>
  )
}

export default Index

// ------------------------------ QUERY
export const query = graphql`
  {
    page: prismicProjectsPage {
      uid
      data {
        meta_title
        meta_description
      }
    }
  }
`